"use client";

import _ from "lodash";
import { useCallback, useEffect, useState, useTransition } from "react";
import { MT5GetTradingType, MT5GetAccountType, MT5CreateAccount } from "@/lib/actions";
import { Button } from "@/components/custom";
import { Select, SelectItem, Form } from "@nextui-org/react";
import { useSecurityPin } from "@/components/modals/security-pin";
import useSwalModal from "@/app/context/useSwalModal";
import { useRefresh } from "@/app/context/useRefresh";
import { z } from "zod";

const CreateMT5AccountForm = ({ onClose, t }: { onClose?: () => void; t: any }) => {
  const leverageOptions = [
    { value: 30, label: "1:30" },
    { value: 50, label: "1:50" },
    { value: 100, label: "1:100" },
    { value: 200, label: "1:200" },
    { value: 500, label: "1:500" },
    // { value: 1000, label: "1:1000" },
  ];
  const [accountType, setAccountType] = useState<{ id: number; name: string }[]>([]);
  const [tradingType, setTradingType] = useState<{ id: number; name: string }[]>([]);
  const [pending, startTransition] = useTransition();
  const [submitting, startSubmitting] = useTransition();

  const { openSecurityPinModal } = useSecurityPin();
  const { OpenAlertModal, OpenNormalModal } = useSwalModal();
  const { refresh } = useRefresh();

  const [errors, setErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    startTransition(async () => {
      try {
        const [accountType, tradingType] = await Promise.all([MT5GetAccountType(), MT5GetTradingType()]);
        setAccountType(accountType);
        setTradingType(tradingType);
      } catch (error) {
        console.error(error);
        setAccountType([]);
        setTradingType([]);
      }
    });
  }, []);

  const ActionHandler = useCallback((formData: FormData) => {
    const schema = z.object({
      type: z.string().min(1, { message: t("input.invalid.field_required") }),
      account_type: z.string().min(1, { message: t("input.invalid.field_required") }),
      leverage: z.string().min(1, { message: t("input.invalid.field_required") }),
    });

    const data = schema.safeParse(Object.fromEntries(formData.entries()));

    if (data.success)
      openSecurityPinModal().then((e) => {
        startSubmitting(async () => {
          const res = await MT5CreateAccount({
            type: formData.get("type") as string,
            account_type: formData.get("account_type") as string,
            leverage: formData.get("leverage") as string,
            tmp_user: e,
          });

          console.log("Create Account Response: ", res);

          if (res) {
            if (res.status_code === 200) {
              refresh();
              OpenNormalModal(t("success"), t("account-created")).then((e) => {
                e.isConfirmed && onClose && onClose();
              });
            } else {
              OpenAlertModal(t("warning"), res.message);
            }
          }
        });
      });
    else {
      data.error.errors.forEach((e) =>
      { 
        console.log(e);
        setErrors((prev) => ({ ...prev, [e.path[0]]: e.message }));
      });
    }
  }, []);

  return (
    <Form className="size-full space-y-4" validationBehavior="aria" action={ActionHandler}>
      <Select
        size="sm"
        aria-label="Account Type"
        name="type"
        label={t("account-type")}
        items={accountType}
        isLoading={pending}
        isDisabled={submitting}
        isRequired
        isInvalid={!!errors.type}
        errorMessage={errors.type}
        onFocus={() => setErrors({})}
      >
        {(item) => <SelectItem key={item.id} title={item.name} />}
      </Select>
      <Select
        size="sm"
        aria-label="Trading Type"
        name="account_type"
        label={t("trading-type")}
        items={tradingType}
        isLoading={pending}
        isDisabled={submitting}
        isRequired
        isInvalid={!!errors.account_type}
        errorMessage={errors.account_type}
        onFocus={() => setErrors({})}
      >
        {(item) => <SelectItem key={item.id} title={item.name} />}
      </Select>
      <Select
        size="sm"
        aria-label="Leverage"
        name="leverage"
        label={t("leverage")}
        items={leverageOptions}
        isLoading={pending}
        isDisabled={submitting}
        isRequired
        isInvalid={!!errors.leverage}
        errorMessage={errors.leverage}
        onFocus={() => setErrors({})}
      >
        {(item) => <SelectItem key={item.value} title={item.label} />}
      </Select>
      <Button isDisabled={submitting} type="submit">
        {t("create-account")}
      </Button>
    </Form>
  );
};

export default CreateMT5AccountForm;
