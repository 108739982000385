"use client";

import _ from "lodash";
import Image from "next/image";
import { Link, useRouter } from "@/i18n/routing";
import { CircularProgress } from "@nextui-org/progress";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards, Pagination as SwiperPagination } from "swiper/modules";
import { memo, startTransition, useCallback, useEffect, useState } from "react";
import { userContractsData } from "@/lib/api";
import { formatNumber } from "@/lib/helper";
import { LineAreaChart } from "@/components/line-chart";
import { useKYCStatus } from "@/app/context/useKYCStatus";
import TickerTapeWidget from "@/components/ticker-tape-widget";
import { GetAllInvestmentPlansReturnType, Mt5Account } from "@/lib/declaration";
import KycBanner from "@/components/kyc-banner";
import { useTranslations } from "next-intl";
import useSwalModal from "@/app/context/useSwalModal";
import { GetAllUserContracts, MT5GetAllAccount, MT5TerminateAccount } from "@/lib/actions";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import clsx from "clsx";
import { useAccountType } from "@/app/context/useAccountType";
import { Button } from "@/components/custom";
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Spacer } from "@nextui-org/react";
import { useModal } from "@/app/context/useModal";
import { useSecurityPin } from "@/components/modals/security-pin";
import { GrAdd } from "react-icons/gr";
import { MdArrowOutward } from "react-icons/md";
import MT5DepositForm from "@/components/mt5/deposit-form";
import CreateMT5AccountForm from "@/components/mt5/create-account-form";

import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";

dayjs.extend(LocalizedFormat);

const InvestmentPlanSection = ({ investmentPlans }: { investmentPlans: userContractsData[] }) => {
  //My Investment Sliders
  const t = useTranslations();
  const { OpenKYCIncompleteModal } = useSwalModal();
  const { KYCStatusCode } = useKYCStatus();
  const Label = ({ label, icon, value }: { label: string; icon: string; value: string }) => (
    <div className="flex w-fit items-center gap-x-2">
      <div className="flex size-10 items-center justify-center rounded-full bg-[#FFCA05]">
        <i className={`icon-${icon} text-white`}></i>
      </div>
      <div>
        <p className="text-[0.813rem] font-[300] text-[#8D8D8D]">{label}</p>
        <h3 className="font-bold text-[#212121]">{value}</h3>
      </div>
    </div>
  );

  try {
    if (investmentPlans.length === 0) throw new Error("No data found");
    return (
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[SwiperPagination]}
        className="w-full !pb-[32px] sm:max-w-[480px]"
      >
        {investmentPlans.map((item, index) => (
          <SwiperSlide key={index} className="w-full">
            <Link
              href="/investment"
              onClick={(e) => {
                if (KYCStatusCode !== "KYC_COMPLETED") {
                  e.preventDefault();
                  OpenKYCIncompleteModal();
                }
              }}
              className="h-auto w-full"
            >
              <div className="flex justify-between rounded-lg bg-gradient-to-br from-[#fffae9] to-[#ffe179] p-4 shadow-md">
                <div className="space-y-4">
                  <Label label={t("plan")} icon="invest" value={item.contract.investment_plan.name} />
                  <Label label={t("invest-capital")} icon="capital" value={`$${formatNumber(item.amount.toString())}`} />
                  <Label
                    label={t("accumulated-profit")}
                    icon="profit"
                    value={`$${formatNumber(item.total_profit.toString())}`}
                  />
                  <Label
                    label={t("matured-date")}
                    icon="date"
                    value={dayjs(item.end_date, "DD-MM-YYYY HH:mm:ss").format("LL")}
                  />
                </div>
                <CircularProgress
                  value={item.remaining_days}
                  maxValue={item.total_days}
                  classNames={{
                    svg: clsx("!size-36 @2xl:size-48"),
                    indicator: "stroke-[#FFCA05]",
                    track: "stroke-white",
                    value: "text-3xl font-semibold text-white",
                    label: "text-[20px] font-semibold absolute max-w-16 text-center leading-5",
                  }}
                  strokeWidth={3}
                  showValueLabel={false}
                  label={
                    <div className="">
                      <span className="text-[20px] font-bold">{item.remaining_days}</span>
                      <span className="text-[14px]">/{t("days", { number: item.total_days })}</span>
                    </div>
                  }
                />
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    );
  } catch (error) {
    console.error(error);
    return (
      <h1 className="flex min-h-60 flex-col items-center justify-center">
        <span className="icon-noData mb-4 text-[4rem] text-[#E0E0E0] lg:text-[4vw]" />
        {t("no-celn-fund")}
      </h1>
    );
  }
};

export default function Dashboard({ searchParams }: { searchParams: Record<string, string | undefined> }) {
  const t = useTranslations();

  const router = useRouter();

  const { accountType, SwitchAccount } = useAccountType();
  const { KYCStatusCode: KYCStatus } = useKYCStatus();
  const { OpenKYCIncompleteModal } = useSwalModal();

  const [hash, setHash] = useState<string>("");
  const [investmentPlans, setInvestmentPlans] = useState<userContractsData[]>([]);
  const [mt5Accounts, setMt5Accounts] = useState<Mt5Account[]>([]);

  const availablePlansData = [
    { id: 1, name: "CELN LITE" },
    { id: 2, name: "CELN ADVANCE" },
    { id: 3, name: "CELN PRO" },
  ];

  useEffect(() => {
    if ("action" in searchParams)
      if (searchParams.action === "KYC_NOT_FOUND") {
        OpenKYCIncompleteModal().then(() => {
          router.push("/");
        });
      }
  }, [searchParams]);

  useEffect(() => {
    startTransition(async () => {
      const res = await MT5GetAllAccount({
        length: 4,
        start: 0,
      });

      const accounts = _.chain(res?.mt5_accounts)
        .sort((a, b) => dayjs(a.updated_at, "YYYY-MM-DD HH:mm:ss").unix() - dayjs(b.updated_at, "YYYY-MM-DD HH:mm:ss").unix())
        .value();

      setMt5Accounts(accounts || []);
    });

    startTransition(async () => {
      try {
        const res = await GetAllUserContracts(10, 0);
        if (!res) return;
        setInvestmentPlans(res.user_contracts);
      } catch (e) {
        console.error(e);
      }
    });
  }, [hash]);

  return (
    <>
      {!["KYC_PENDING", "KYC_COMPLETED"].some((e) => KYCStatus === e) ? (
        <KycBanner />
      ) : (
        // <></>
        <>
          <div className="mt-4 flex items-center justify-between px-4 sm:px-0">
            <h1 className="text-[20px] font-bold">{t("dashboard")}</h1>
            <Button className="" radius="full" onPress={() => SwitchAccount()}>
              {t("switch-to", {
                accountType: accountType === "CELN" ? "CFD" : "CELN",
              })}
            </Button>
          </div>
          {/* <TickerTapeWidget /> */}
        </>
      )}
      {accountType === "CFD" && (
        <div className="container relative mx-auto flex w-full flex-col gap-4 overflow-hidden px-4 font-bold text-[#212121] @[660px]:grid sm:px-0">
          <Spacer y={4} />
          <div className="flex flex-col gap-14 @[660px]:flex-row-reverse">
            <div className="order-2 h-fit w-full @[660px]:w-fit">
              <OpenAccountButton />
            </div>
            <div className="order-1 w-full">
              {["KYC_PENDING", "KYC_COMPLETED"].some((e) => KYCStatus === e) && <TickerTapeWidget />}
            </div>
          </div>
          <Spacer y={4} />
          <div className="flex justify-between">
            <h2 className="text-base">{t("all-account")}</h2>
            {mt5Accounts.length > 0 && (
              <Link
                href="/mt5-accounts"
                onClick={(e) => {
                  if (KYCStatus !== "KYC_COMPLETED") {
                    e.preventDefault();
                    OpenKYCIncompleteModal();
                  }
                }}
                className="text-[0.75rem] font-[300] underline hover:font-bold"
              >
                {t("view-all")}
              </Link>
            )}
          </div>
          {/* {MT5AccountCards(mt5Accounts, setHash)} */}
          <MT5AccountCards accounts={mt5Accounts} setHash={setHash} />
        </div>
      )}
      {accountType === "CELN" && (
        <>
          <div className="relative flex w-full grid-cols-2 flex-col gap-12 overflow-hidden px-4 font-bold text-[#212121] @[660px]:container @[660px]:mx-auto @[660px]:grid sm:px-0">
            <div className="relative">
              <h2 className="mb-5 text-base">{t("available-plans")}</h2>
              <Swiper
                effect={"cards"}
                grabCursor={true}
                modules={[EffectCards]}
                className="cardSwiper mx-auto w-full max-w-[320px]"
              >
                {(() => {
                  try {
                    return (availablePlansData as GetAllInvestmentPlansReturnType[]).map((item, index) => {
                      return (
                        <SwiperSlide key={index} className="aspect-[800/758]">
                          <InvestmentPlanCard plan={item} />
                        </SwiperSlide>
                      );
                    });
                  } catch (e) {
                    return (
                      <h1 className="flex h-64 w-full flex-col items-center justify-center lg:size-full">
                        <span className="icon-noData mb-4 text-[4rem] text-[#E0E0E0] lg:text-[4vw]" />
                        {t("no-celn-fund")}
                      </h1>
                    );
                  }
                })()}
              </Swiper>
            </div>
            <div className="w-full font-bold text-[#212121]">
              <div className="mb-4 flex items-center justify-between">
                <h2 className="text-base">{t("my-celn-fund")}</h2>
                <Link
                  href="/investment"
                  onClick={(e) => {
                    if (KYCStatus !== "KYC_COMPLETED") {
                      e.preventDefault();
                      OpenKYCIncompleteModal();
                    }
                  }}
                  className="text-[0.75rem] font-[300] underline hover:font-bold"
                >
                  {t("view-all")}
                </Link>
              </div>
              {/* <Swiper
            effect={"cards"}
            grabCursor={true}
            modules={[EffectCards]}
            className="cardSwiper mx-auto w-full max-w-[320px]"
          >
            {availablePlans(availablePlansData as any)}
          </Swiper> */}
              <InvestmentPlanSection investmentPlans={investmentPlans} />
            </div>
          </div>
          <h1 className="my-2 px-4 text-base font-bold">{t("profit")}</h1>
          <LineAreaChart />
        </>
      )}
    </>
  );
}

const MT5AccountCards = ({ accounts, setHash }: { accounts?: Mt5Account[]; setHash: (hash: string) => void }) => {
  const t = useTranslations();
  const { openSecurityPinModal } = useSecurityPin();
  const router = useRouter();
  const { OpenNormalModal, OpenConfirmModal, OpenKYCIncompleteModal } = useSwalModal();
  const [encryptedId, setEncryptedId] = useState<string>();
  const [accountId, setAccountId] = useState<string>();
  const [accountBalance, setAccountBalance] = useState<number>();
  const [MT5DepositModal, Mt5DepositDisclosure] = useModal({
    title: t("create-deposit"),
    content: (onClose) => (
      <MT5DepositForm onClose={onClose} encryptedId={encryptedId} accountId={accountId} accountBalance={accountBalance} />
    ),
  });

  const OpenDepositModal = (encryptedId: string, accountId: string, accountBalance: number) => {
    setEncryptedId(encryptedId);
    setAccountId(accountId);
    setAccountBalance(accountBalance);
    Mt5DepositDisclosure.onOpen?.();
  };
  const OpenTerminationModal = useCallback((encryptedId: string, accountId: string) => {
    OpenConfirmModal(
      t("terminate-account"),
      t("terminate-account-confirm", {
        accountId,
      })
    ).then((e) => {
      if (e.isConfirmed) {
        openSecurityPinModal().then((tmpUser) => {
          startTransition(async () => {
            const res = await MT5TerminateAccount(encryptedId, tmpUser);
            if ("error" in res) {
              console.error(res.error);
              return;
            }

            if (res) {
              OpenNormalModal(
                t("mt5-account-termination-success.title"),
                t("mt5-account-termination-success.message", {
                  accountId,
                })
              ).then(() => {
                setHash(Math.random().toString(36).substring(7));
              });
            }
          });
        });
      }
    });
  }, []);

  const Card = ({ account }: { account: Mt5Account }) => (
    <>
      {process.env.NODE_ENV === "development" && (
        <span className="pointer-events-none absolute right-0 top-0 z-50 m-2 text-tiny opacity-20">
          {dayjs(account.updated_at, "YYYY-MM-DD HH:mm:ss").format("lll")}
        </span>
      )}
      <div
        role={account.status === 10 ? "button" : undefined}
        onClick={() => {
          if (account.status === 10) router.push(`/mt5-accounts/${account.encrypted_id}`);
        }}
        className={clsx("relative grid size-full grid-rows-3 gap-4", {
          "[&_*]:opacity-75": account.status === 11 || account.status === 21,
        })}
      >
        <div className="flex h-full gap-2">
          <div
            className={clsx(
              "flex h-8 w-fit items-center justify-center rounded-md p-1.5 px-2 text-tiny font-normal text-white",
              {
                "bg-[#0096E7]": account.status === 1, // pending
                "bg-[#FF8A05]": account.status === 2, // processing
                "bg-[#00AB58]": account.status === 10, // active
                "bg-[#646464]": account.status === 11, // inactive
                "bg-[#EE2928]": account.status === 20, // rejected
                "bg-[#8D8D8D]": account.status === 21, // terminated
              }
            )}
          >
            {(() => {
              switch (account.status) {
                case 1:
                  return t("pending");
                case 2:
                  return t("processing");
                case 10:
                  return t("active");
                case 11:
                  return t("inactive");
                case 20:
                  return t("rejected");
                case 21:
                  return t("terminated");
                default:
                  return "-";
              }
            })()}
          </div>
          <div className="flex h-8 w-fit items-center justify-center rounded-md bg-[#D5AB2F] p-1.5 px-2 text-tiny font-normal text-white">
            MT5
          </div>
          <div className="flex h-8 w-fit items-center justify-center p-1.5 text-tiny font-normal">
            {account.account_number ?? "-"}
          </div>
          <Dropdown placement="right">
            <DropdownTrigger>
              {/* <GoKebabHorizontal size={24} color="black" className="" /> */}
              <svg
                className={clsx("ml-auto size-6 text-black", {
                  "pointer-events-none opacity-40": account.status !== 10,
                })}
                fill="currentColor"
                viewBox="0 0 24 24"
                role="button"
              >
                <path
                  fill="currentColor"
                  d="M4 12c0-1.104.896-2 2-2s2 .896 2 2c0 1.104-.896 2-2 2s-2-.896-2-2zm6 0c0-1.104.896-2 2-2s2 .896 2 2c0 1.104-.896 2-2 2s-2-.896-2-2zm6 0c0-1.104.896-2 2-2s2 .896 2 2c0 1.104-.896 2-2 2s-2-.896-2-2z"
                ></path>
              </svg>
              {/* <Button
        isIconOnly
        variant="light"
        isDisabled={account.status !== 10}
        className="ml-auto flex items-center justify-center border-none"
      ></Button> */}
            </DropdownTrigger>
            <DropdownMenu
              aria-label="Static Actions"
              className="left-0 top-0"
              onAction={(e) => {
                if (e === "delete") {
                  OpenTerminationModal(account.encrypted_id, account.account_number!);
                }
                if (e === "view-details") {
                  router.push(`/mt5-accounts/${account.encrypted_id}`);
                }
              }}
            >
              <DropdownItem key="view-details">{t("view-details")}</DropdownItem>
              <DropdownItem key="delete" className="text-danger" color="danger">
                {t("terminate-account")}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div className="flex items-center justify-between gap-2">
          <div className="flex h-full items-center justify-center text-lg font-bold">
            {account.status === 10 || account.status === 11 ? `$${account.listing_balance}` : "--"}
          </div>
          <CreateDepositButton account={account} setHash={setHash} />
        </div>
        <div className="flex items-end gap-2">
          <div className="flex h-8 w-fit items-center justify-center rounded-md bg-white p-1.5 text-tiny font-normal text-[#A67A00]">
            1:{parseInt(account.leverage.split(".")[0])}
          </div>
          <div className="flex h-8 w-fit items-center justify-center rounded-md bg-white p-1.5 text-tiny font-normal text-[#A67A00]">
            {account.display_type}
          </div>
          <div className="flex h-8 w-fit items-center justify-center rounded-md bg-white p-1.5 text-tiny font-normal text-[#A67A00]">
            {account.display_account_type}
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <MT5DepositModal />
      {/* <Swiper
        effect={"cards"}
        modules={[EffectCards]}
        className="mx-auto w-full !px-4 @[475px]:!hidden"
        slidesPerView={accounts?.length === 0 ? "auto" : 1}
        spaceBetween={10}
      >
        {(() => {
          try {
            if (accounts?.length === 0) throw new Error("No data found");
            return (
              <>
                {accounts?.map((account, index) => (
                  <SwiperSlide
                    key={index}
                    className={clsx("w-full rounded-xl bg-gradient-to-br from-[#FFFBE9] to-[#FFF1B4] p-3 px-6", {
                      "grayscale filter": account.status === 21,
                    })}
                  >
                    <Card account={account} />
                  </SwiperSlide>
                ))}
              </>
            );
          } catch (e) {
            return (
              <SwiperSlide className="!w-full">
                <h1 className="flex min-h-60 w-full flex-col items-center justify-center text-tiny text-black/40">
                  <span className="icon-noData mb-2 ms-1 text-[4rem] text-[#E0E0E0] lg:text-[48px]" />
                  {t("no-account")}
                </h1>
              </SwiperSlide>
            );
          }
        })()}
      </Swiper> */}
      <div className="mx-auto grid w-full gap-2 @[475px]:grid-cols-1 @[700px]:grid-cols-2">
        {(() => {
          try {
            if (accounts?.length === 0) throw new Error("No data found");
            return (
              <>
                {accounts?.map((account, index) => (
                  <div
                    key={index}
                    className={clsx("relative w-full rounded-xl bg-gradient-to-br from-[#FFFBE9] to-[#FFF1B4] p-3 px-6", {
                      "grayscale filter": account.status === 21,
                    })}
                  >
                    <Card account={account} />
                  </div>
                ))}
              </>
            );
          } catch (e) {
            return (
              <div className="col-span-full">
                <h1 className="flex min-h-60 w-full flex-col items-center justify-center text-tiny text-black/40">
                  <span className="icon-noData mb-2 ms-1 text-[4rem] text-[#E0E0E0] lg:text-[48px]" />
                  {t("no-account")}
                </h1>
              </div>
            );
          }
        })()}
      </div>
      {/* <Swiper
        effect={"slide"}
        grabCursor={true}
        className="mx-auto !hidden w-full !px-4 @[475px]:!block"
        slidesPerView={accounts?.length === 0 ? "auto" : 2.05}
        spaceBetween={10}
      >
        {(() => {
          try {
            if (accounts?.length === 0) throw new Error("No data found");
            return (
              <>
                {accounts?.map((account, index) => (
                  <SwiperSlide
                    key={index}
                    className={clsx("w-full rounded-xl bg-gradient-to-br from-[#FFFBE9] to-[#FFF1B4] p-3 px-6", {
                      "grayscale filter": account.status === 21,
                    })}
                  >
                    <Card account={account} />
                  </SwiperSlide>
                ))}
              </>
            );
          } catch (e) {
            return (
              <SwiperSlide className="!w-full">
                <h1 className="flex min-h-60 w-full flex-col items-center justify-center text-tiny text-black/40">
                  <span className="icon-noData mb-2 ms-1 text-[4rem] text-[#E0E0E0] lg:text-[48px]" />
                  {t("no-account")}
                </h1>
              </SwiperSlide>
            );
          }
        })()}
      </Swiper> */}
    </>
  );
};

function OpenAccountButton() {
  const { KYCStatusCode: KYCStatus } = useKYCStatus();
  const { OpenKYCIncompleteModal } = useSwalModal();
  const t = useTranslations();
  const [CreateMT5Modal, createMt5Disclosure] = useModal({
    title: t("create-mt5-account"),
    content: (onClose) => <CreateMT5AccountForm t={t} onClose={onClose} />,
  });

  return (
    <>
      <CreateMT5Modal />
      <div className="relative mx-auto flex flex-col gap-4 @[660px]:flex-row">
        {/* <div className="aspect-[1.25] size-full max-h-60 flex-grow-0 rounded-lg bg-gradient-to-br from-[#FFFBE9] to-[#FFF1B4] @[660px]:aspect-auto @[660px]:max-h-none @[660px]:w-auto @[660px]:flex-grow @[660px]:rounded-2xl"></div> */}
        <div
          role="button"
          className="relative aspect-[2.4] max-h-48 w-full space-y-2 rounded-lg bg-black bg-opacity-10 bg-[url(/assets/img/open-account-bg.webp)] bg-cover bg-center p-4 font-normal text-[#BBBBBB] @[660px]:aspect-[1.05] @[660px]:max-h-none @[660px]:w-[240px] @[660px]:rounded-2xl"
          onClick={() => {
            if (KYCStatus !== "KYC_COMPLETED") {
              OpenKYCIncompleteModal();
              return;
            }

            createMt5Disclosure.onOpen?.();
          }}
        >
          <GrAdd size={32} color="#F6C649" />
          <span className="block text-[32px] font-bold leading-none text-[#F6C649]">{t("open-account")}</span>
          <span className="block leading-none">{t("open-account-text")}</span>
          <MdArrowOutward size={32} color="#F6C649" className="place-self-end justify-self-end" />
        </div>
      </div>
    </>
  );
}

const InvestmentPlanCard = ({ plan }: { plan: GetAllInvestmentPlansReturnType }) => {
  const { KYCStatusCode } = useKYCStatus();
  const { OpenKYCIncompleteModal } = useSwalModal();
  const router = useRouter();
  const textColour = (id: number) => {
    switch (id) {
      case 1:
        return "black";
      case 2:
        return "black";
      case 3:
        return "white";
      default:
        return "black";
    }
  };
  return (
    <Link
      href={`/investment/${plan.name.replace(" ", "-").toLowerCase()}`}
      onClick={(e) => {
        if (KYCStatusCode !== "KYC_COMPLETED") {
          e.preventDefault();
          OpenKYCIncompleteModal().then(() => {
            router.push("/kyc");
          });
        }
      }}
    >
      <Image
        src={`/assets/img/invest/plan-${plan.id}.png`}
        alt={plan.name}
        width={800 / 2}
        height={758 / 2}
        className="absolute inset-0 size-full"
      />
      <svg className="absolute inset-0 size-full object-contain" viewBox="0 0 800 758">
        <text
          x="5%"
          y="80%"
          dominantBaseline="middle"
          textAnchor="left"
          fill={textColour(plan.id)}
          fontSize={80}
          fontWeight="bold"
        >
          {plan.name}
        </text>
        {/* <text x="6%" y="88%" dominantBaseline="middle" textAnchor="left" fill={textColour(item.id)} fontSize={45} opacity={0.6} fontWeight={200}>
    Investment Plan
  </text> */}
      </svg>
    </Link>
  );
};

const CreateDepositButton = ({ account, setHash }: { account: Mt5Account; setHash: (hash: string) => void }) => {
  const t = useTranslations();
  const [encryptedId, setEncryptedId] = useState<string>();
  const [accountId, setAccountId] = useState<string>();
  const [accountBalance, setAccountBalance] = useState<number>();
  const [MT5DepositModal, Mt5DepositDisclosure] = useModal({
    title: t("create-deposit"),
    content: (onClose) => (
      <MT5DepositForm onClose={onClose} encryptedId={encryptedId} accountId={accountId} accountBalance={accountBalance} />
    ),
  });

  const OpenDepositModal = (encryptedId: string, accountId: string, accountBalance: number) => {
    setEncryptedId(encryptedId);
    setAccountId(accountId);
    setAccountBalance(accountBalance);
    Mt5DepositDisclosure.onOpen();
  };
  return (
    <>
      <MT5DepositModal />
      <Button
        className="font-normal text-black"
        isDisabled={account.status !== 10}
        startContent={<span className="icon-deposit text-[16px]" />}
        onPress={() => OpenDepositModal(account.encrypted_id, account.account_number!, Number(account.balance))}
      >
        {t("deposit")}
      </Button>
    </>
  );
};
